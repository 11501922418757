import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import { Helmet } from 'react-helmet';
import { useStore } from 'contexts/store';
import Footer from './Footer';
import './Dashboard.scss';
import TopNav from '../TopNav';
import { useAuth } from '../../contexts/auth/auth';
import MasqueradingBanner from '../Masquerading/MasqueradingBanner';
import TabStore from 'components/TopNav/Tabs/TabStore';
import clsx from 'clsx';
import OnboardingModule from 'components/Onboarding/OnboardingModule';
import { RequestBanner } from 'components/RequestBanner';
import { useOwnerDetails } from 'api/owners';

const Dashboard = ({ className, children, page, doNotShowNav }) => {
  const { isMasquerading, id: ownerId } = useAuth();
  const storeData = useStore();
  const { data: bannerData, isLoading: isBannerLoading } = useOwnerDetails(ownerId);
  const showRequestsBanner = !isBannerLoading && bannerData.hasRequests;
  return (
    <>
      {isMasquerading && <MasqueradingBanner identifier={storeData.owner.email} />}
      {!doNotShowNav && <TopNav inStore page={page} />}
      <Layout className={clsx('Dashboard', isMasquerading && 'masquerading')}>
        <Helmet>
          {storeData?.storeName && <title>{storeData.storeName} | The Host Co</title>}
        </Helmet>

        <div className={clsx(className, 'Dashboard_desktop')}>
          {!doNotShowNav && (
            <>
              <aside className="sider" tabIndex={0}>
                <TabStore mode="inline" store={storeData} initialActiveTab="dashboard" />
              </aside>
              <div className="sider-backdrop" />
              <Layout className="Dashboard_layout">
                <RequestBanner visible={showRequestsBanner} />
                <OnboardingModule />
                <Layout.Content className="Dashboard_content">{children}</Layout.Content>
                <Layout.Footer className="Dashboard_footer desktop">
                  <Footer />
                </Layout.Footer>
              </Layout>
            </>
          )}
        </div>
      </Layout>
    </>
  );
};

Dashboard.propTypes = {
  className: PropTypes.string,
  page: PropTypes.string.isRequired,
  children: PropTypes.node,
  doNotShowNav: PropTypes.bool,
  defaultStore: PropTypes.object,
};

export default Dashboard;
