import React from 'react';
import { Col, Row } from 'antd';
import { Radio, RadioGroup, TextField } from 'ui-kit';
import FormControl from 'ui-kit/FormControl';
import { useFormContext } from 'react-hook-form';

interface Props {
  value: string;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error: boolean;
  errorMessage: React.ReactNode;
}

export default function AlertTresholdField({ error, errorMessage, value, ...field }: Props) {
  const { setValue, watch } = useFormContext();
  const notifyThreshold = watch('notifyThreshold');

  const handleChange = (e) => {
    setValue('notifyThreshold', e.target.value);
    setValue(field.name, null, { shouldValidate: true, shouldDirty: true });
  };

  return (
    <Row gutter={[0, 24]}>
      <Col xs={24}>
        <FormControl label="Do you want to be notified if you are running low?">
          <RadioGroup value={notifyThreshold} onChange={handleChange}>
            <div>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </div>
          </RadioGroup>
        </FormControl>
      </Col>
      {notifyThreshold && (
        <Col xs={24}>
          <TextField
            className="quantity-field"
            label="At what amount should we notify you?"
            required
            type="number"
            value={value ?? ''}
            error={error}
            errorMessage={errorMessage}
            {...field}
          />
        </Col>
      )}
    </Row>
  );
}
