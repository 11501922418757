import React, { useMemo, useRef } from 'react';
import { ProductSubcategory } from 'api/types/product';
import { Select, Typography } from 'ui-kit';
import './SelectSubcategory.scss';

interface Option {
  label: string;
  value: string;
}

interface Props {
  label?: string;
  value: Option['value'];
  name?: string;
  onChange: (value: Option['value']) => void;
  getOptions?: (defaultOptions: Option[]) => Option[];
  disabled?: boolean;
}

const BASE_CLASS = 'SelectSubcategory';

const defaultOptions: Option[] = Object.keys(ProductSubcategory).map((value) => ({
  value: ProductSubcategory[value],
  label: ProductSubcategory[value],
}));

export default function SelectSubcategory({
  label = 'Category',
  disabled = false,
  getOptions,
  ...rest
}: Props) {
  const ref = useRef<HTMLDivElement>();
  const options = useMemo(() => {
    if (getOptions) {
      return getOptions(defaultOptions);
    }
    return defaultOptions;
  }, []);
  return (
    <div className={BASE_CLASS} ref={ref}>
      <Typography as="span" variant="body2" color="textGray">
        {label}:{' '}
      </Typography>
      <Select
        bordered={false}
        options={options}
        disabled={disabled}
        getPopupContainer={() => ref.current}
        {...rest}
      />
    </div>
  );
}
