import React from 'react';
import { Tooltip as AntTooltip } from 'antd';
import { classNames } from 'utils';
import './tooltip.scss';

const Tooltip = ({ ...rest }) => {
  return <AntTooltip className={classNames('ui-kit-tooltip')} {...rest} />;
};

export default Tooltip;
