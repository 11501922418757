import AIRBNB from './airbnb.png';
import BOOKING from './booking.png';
import DACK from './dack.png';
import ENSOCONNECT from './ensoconnect.png';
import GUESTY from './guesty.png';
import HOPSITABLE from './hospitable.png';
import HOSTAWAY from './hostaway.png';
import HOSTFULLY from './hostfully.png';
import IGMS from './igms.png';
import LMPM from './lmpm.png';
import LODGIFY from './lodgify.png';
import OWNERREZ from './ownerrez.png';
import STAYFI from './stayfi.png';
import TOUCHSTAY from './touchstay.png';
import VACASA from './vacasa.png';
import VRBO from './vrbo.png';

const logos = {
  AIRBNB,
  BOOKING,
  DACK,
  ENSOCONNECT,
  GUESTY,
  HOPSITABLE,
  HOSTAWAY,
  HOSTFULLY,
  IGMS,
  LMPM,
  LODGIFY,
  OWNERREZ,
  STAYFI,
  TOUCHSTAY,
  VACASA,
  VRBO,
};

export { logos };
