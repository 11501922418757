import React from 'react';
import { IOrder } from 'api/order_sdk';
import { formatCurrency } from 'utils';
import dayjs from 'dayjs';

import Table from 'components/Table';
import { Typography } from 'ui-kit';
import RefundButton from 'components/RefundButton';
import { ERefundType } from 'components/RefundButton/contants';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

export interface IOrderProductsTable {
  order: IOrder;
  onRefundCompleted: () => void;
}

const OrderProductsTable = ({ order, onRefundCompleted }: IOrderProductsTable) => (
  <Table
    rowKey="id"
    columns={[
      { title: 'Product Name', dataIndex: 'product_name', key: 'productName' },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
      },
      {
        title: 'Date of Service',
        dataIndex: 'delivery_date',
        key: 'deliveryDate',
        render: (text) => {
          if (text) {
            return <Typography variant="body2">{dayjs(text).format('Do MMMM YYYY')}</Typography>;
          }
          return <Typography variant="body2">n/a</Typography>;
        },
      },
      {
        title: 'Price Per Item',
        key: 'price',
        dataIndex: 'price',
        render: (text, record) => (
          <Typography variant="body2">{formatCurrency(text, record.currency)}</Typography>
        ),
      },
      {
        title: 'Is Refunded',
        key: 'is_refunded',
        dataIndex: 'is_refunded',
        render: (text) =>
          text ? (
            <CheckCircleOutlined style={{ color: 'green', fontSize: '14px' }} />
          ) : (
            <CloseCircleOutlined
              style={{
                color: 'red',
                fontSize: '14px',
              }}
            />
          ),
      },
      {
        title: 'Actions',
        key: 'actions',
        render: (product) => (
          <RefundButton
            nonRefundableReason={product.non_refundable_reason}
            disabled={!product.is_refundable}
            title="Refund item"
            refundType={ERefundType.ORDER_PRODUCT}
            order={order}
            product={product}
            onRefundCompleted={onRefundCompleted}
          />
        ),
      },
    ]}
    dataSource={order.products}
    pagination={false}
    className="DashboardSales_subtable"
  />
);

export default OrderProductsTable;
