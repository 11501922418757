import React, { useState } from 'react';
import { ProductSubcategory } from 'api/types/product';
import { TextField } from 'ui-kit';
import { Controller, useFormContext } from 'react-hook-form';
import { getFieldError } from '../utils';
import SelectSubcategory from 'components/SelectSubcategory';

interface Props {
  mode?: 'create' | 'edit';
}

export default function ChooseCategory({ mode }: Props) {
  const [inactive, setInactive] = useState(false);
  const { control, getValues, watch } = useFormContext();
  const productSubcategory = watch('product.subcategory');

  React.useEffect(() => {
    const { product, subcategory } = getValues();

    if (
      mode !== 'edit' &&
      subcategory !== ProductSubcategory.SomethingElse &&
      subcategory === product.subcategory &&
      product.id !== 0
    ) {
      setInactive(true);
    }
  }, []);

  return (
    <div>
      <Controller
        control={control}
        name="product.subcategory"
        render={({ field: { ref: _ref, ...rest } }) => {
          return <SelectSubcategory disabled={inactive} {...rest} />;
        }}
      />
      {productSubcategory === ProductSubcategory.SomethingElse && (
        <Controller
          control={control}
          name="subcategoryName"
          render={({ field: { value, ref, ...rest }, fieldState }) => (
            <TextField
              label="Category name"
              fullWidth
              value={value ?? ''}
              inputRef={ref}
              {...getFieldError(fieldState)}
              {...rest}
            />
          )}
        />
      )}
    </div>
  );
}
