import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { UploadImage } from 'ui-kit';
import TextField from 'ui-kit/TextField';
import ChooseSubcategory from './ChooseSubcategory';
import PriceField from './PriceField';
import './ProductBaseForm.scss';
import { getFieldError } from '../utils';
import { DEFAULT_PRODUCT_DESCRIPTION } from '../SelectProduct/utils';
import { ProductCategory } from 'api/types/product';

interface Props {
  mode?: 'create' | 'edit';
}

const BASE_CLASS = 'ProductBaseForm';
export default function ProductBaseform({ mode }: Props) {
  const { control, getValues } = useFormContext();
  const { product } = getValues();
  const { image, category } = product || {};
  const isLinkProduct = category === ProductCategory.LINK;
  return (
    <div className={BASE_CLASS}>
      <section className={`${BASE_CLASS}-main-section`}>
        <div className={`${BASE_CLASS}-main-section__details`}>
          <Controller
            control={control}
            name="product.name"
            render={({ field: { value, ref, ...rest }, fieldState }) => (
              <TextField
                label="Product name"
                required
                fullWidth
                value={value ?? ''}
                inputRef={ref}
                {...getFieldError(fieldState)}
                {...rest}
              />
            )}
          />
          {category !== ProductCategory.LINK && (
            <PriceField baseClass={`${BASE_CLASS}-main-section__details`} />
          )}
          <ChooseSubcategory mode={mode} />
        </div>
        <div className={`${BASE_CLASS}-main-section__uploadImage`}>
          <Controller
            control={control}
            name="product.image"
            render={({ field }) => (
              <UploadImage
                label="Product image"
                required
                previewUrl={image}
                helperText="Make sure your product has bright lighting and a neutral background"
                {...field}
              />
            )}
          />
        </div>
      </section>
      <Controller
        control={control}
        name="product.description"
        render={({ field: { value, ref, ...rest }, fieldState }) => (
          <TextField
            label="Product description"
            {...getFieldError(fieldState)}
            helperText={
              isLinkProduct
                ? "Descriptions for link products won't be seen by guests but you can add information here for your own use."
                : 'Best-selling products have rich, detailed descriptions.'
            }
            required={category !== ProductCategory.LINK}
            fullWidth
            rows={6}
            multiline
            showCount
            inputProps={{
              maxLength: 10000,
            }}
            inputRef={ref}
            value={value ?? DEFAULT_PRODUCT_DESCRIPTION}
            {...rest}
          />
        )}
      />
      {!isLinkProduct && (
        <Controller
          control={control}
          name="product.instructions"
          render={({ field: { value, ref, ...rest } }) => (
            <TextField
              label={<span>Instructions for guests after purchase</span>}
              helperText="If you are offering a rental or an in-home item, let guests know exactly where they can find what they need."
              fullWidth
              rows={6}
              multiline
              showCount
              inputProps={{
                maxLength: 500,
                placeholder:
                  ' Example: Code to the cabinet is 1234, bikes are in the garage or snack basket is in the hallway closet.',
              }}
              inputRef={ref}
              value={value ?? ''}
              {...rest}
            />
          )}
        />
      )}
    </div>
  );
}
