import React from 'react';
import { Col, Row } from 'antd';
import { Radio, RadioGroup, TextField } from 'ui-kit';
import FormControl from 'ui-kit/FormControl';
import { FieldValues, useFormContext, UseFormSetError } from 'react-hook-form';

interface Props {
  value: { label: string }[];
  name: string;
  onChange: (value: { label: string }[]) => void;
  error?: boolean;
  errorMessage?: React.ReactNode;
  setError: UseFormSetError<FieldValues>;
}

export default function ExtraFields({
  error,
  errorMessage,
  value,
  name,
  onChange,
  ...field
}: Props) {
  const { setValue, watch } = useFormContext();
  const includeExtraFields = watch('includeExtraFields');
  const handleChange = (e) => {
    setValue('includeExtraFields', e.target.value);
    setValue(name, []);
  };

  const handleFieldChange = (e) => {
    onChange([{ label: e.target.value }]);
  };

  return (
    <Row gutter={[0, 24]}>
      <Col xs={24}>
        <FormControl label="Requires additional information from guest (e.g. room number or t-shirt size)">
          <RadioGroup value={includeExtraFields} onChange={handleChange}>
            <div>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </div>
          </RadioGroup>
        </FormControl>
      </Col>
      {includeExtraFields && (
        <Col xs={24}>
          <FormControl
            label="Field name"
            required
            error={error}
            fullWidth
            errorMessage={errorMessage}
            helperText="This is the label for the field your guest will see when they add this product to their cart."
          >
            <Col xs={18} md={12}>
              <TextField
                error={error}
                value={value.length > 0 ? value[0].label : ''}
                className=""
                onChange={handleFieldChange}
                {...field}
              />
            </Col>
          </FormControl>
        </Col>
      )}
    </Row>
  );
}
