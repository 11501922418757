import _ from 'lodash';

export const PHONE_NUMBER_REGEX = /^\+1 \(\d{3}\) \d{3}-\d{4}$/;
// eslint-disable-next-line no-useless-escape
export const EMPTY_PHONE_NUMBER_REGEX = /^\+1 \(\_\_\_\) \_\_\_\-\_\_\_\_$/;

export const validateAndFormatPhoneNumber = ({
  phoneNumber,
}: {
  phoneNumber: string;
}): string | null => {
  const pattern = /^\+1\d{10}$/;

  if (pattern.test(phoneNumber)) {
    const areaCode = phoneNumber.slice(2, 5);
    const firstPart = phoneNumber.slice(5, 8);
    const secondPart = phoneNumber.slice(8);

    // Format the phone number as desired
    const formattedPhoneNumber = `+1 (${areaCode}) ${firstPart}-${secondPart}`;

    return formattedPhoneNumber;
  } else {
    return null;
  }
};

export const formatPhoneNumber = ({ phone }: { phone: string | null }) => {
  if (_.isNil(phone)) {
    return null;
  }
  // Remove all non-digit characters from the phone number
  // NOTE: This will remove + sign at the beginning
  const cleaned = phone.replace(/\D/g, '');
  // Return "+" sign at the beginning of phone number
  if (cleaned.length > 0) {
    return `+${cleaned}`;
  }
  return null;
};
