import { createContext, useContext, useEffect, useState } from 'react';

export interface StepWizardContext {
  step: number;
  isInitialStep: boolean;
  handelBack: () => void;
  isLastStep?: boolean;
  next?: () => void;
  prev?: () => void;
  goTo?: (step: number) => void;
  onChangeStep?: (step: number) => void;
  isMassCreate?: boolean;
}

const initialState = {
  isInitialStep: true,
  step: 1,
  handelBack: () => null,
};

export const StepWizardCtx = createContext<StepWizardContext>(initialState);

export const useStepWizard = () => {
  const context = useContext(StepWizardCtx);
  if (context === undefined) {
    throw new Error('useStepWizard must be used within a StepWizardProvider');
  }
  return context;
};

export const useSteps = ({
  steps,
  initialStep = 1,
  onChangeStep = () => null,
  handelBack,
}): StepWizardContext => {
  const [step, setStep] = useState(() => initialStep);
  const isInitialStep = step === 1;
  const isLastStep = steps.length === step;

  const next = (): void => {
    setStep((step) => Math.min(step + 1, steps.length));
  };

  const prev = (): void => {
    setStep((step) => Math.max(step - 1, 1));
  };

  const goTo = (step: number): void => {
    setStep(step);
  };

  useEffect(() => {
    if (!isInitialStep) {
      onChangeStep();
    }
  }, [step]);

  return { step, isInitialStep, isLastStep, next, prev, goTo, handelBack };
};
