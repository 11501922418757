import React from 'react';
import { logos } from './logos';
import { Typography } from 'ui-kit';
import { classNames } from 'utils';

export enum Integrations {
  airbnb,
  booking,
  dack,
  ensoconnect,
  guesty,
  hospitable,
  hostaway,
  hostfully,
  igms,
  lmpm,
  lodgify,
  ownerrez,
  stayfi,
  touchstay,
  vacasa,
  vrbo,
}

const links = [
  {
    url: 'https://www.airbnb.com/hosting/inbox/settings/scheduled-messages',
    icon: <img src={logos.AIRBNB} />,
    id: Integrations.airbnb,
    tooltip: 'Airbnb',
  },
  {
    url: 'https://account.booking.com/sign-in?op_token=EgVvYXV0aCL6AgoUdk8xS2Jsazd4WDl0VW4yY3BaTFMSCWF1dGhvcml6ZRo1aHR0cHM6Ly9zZWN1cmUuYm9va2luZy5jb20vbG9naW4uaHRtbD9vcD1vYXV0aF9yZXR1cm4qmQJVck1CRmhuV1FybzE1ZzhxbzIwc2NrYXpnLUYwYXp6Y2tDX1o3QXpjZ1IwT0Z6RjRla2VXRHlqb2RSSUZWbmNGYi1DR3B4X09id0dTY0w0WF9Qa2tCdDVIbU4zVS1pLTN6c25mMXFrZkppeU9FN1lCc3VJSnVMdjEwbFlCQUlmWElveEhFb0NyZm1MNmpLSVRobmJHbGxuYW4tRllVQWhoS0YzN2VPWEhlUmlXOWFFeTN4SnRJc2ZDd0JKQy1odGFiNTRNdkJtRjVpSDRuT2c0anFBN3hDR1RUalpGclVVVmdjb3UyRDlBSXVwQXlzd3J2RWc9KmV5SnBaQ0k2SW5SeVlYWmxiR3hsY2w5b1pXRmtaWElpZlE9PUIEY29kZSopCI7IEjD6k6ja5MAmOgBCAFias76nBpIBEHRyYXZlbGxlcl9oZWFkZXI',
    icon: <img src={logos.BOOKING} />,
    id: Integrations.booking,
    tooltip: 'Booking',
  },
  {
    url: 'https://apps.dackinc.com/Operator/Home/Login?ReturnUrl=%2Foperator%2F',
    icon: <img src={logos.DACK} />,
    id: Integrations.dack,
    tooltip: 'DACK',
  },
  {
    url: 'https://console.ensoconnect.com/auth/login',
    icon: <img src={logos.ENSOCONNECT} />,
    id: Integrations.ensoconnect,
    tooltip: 'Enso Connect',
  },
  {
    url: 'https://app.guesty.com/auth/login',
    icon: <img src={logos.GUESTY} />,
    id: Integrations.guesty,
    tooltip: 'Guesty',
  },
  {
    url: 'https://my.hospitable.com/user/hello?returnUrl=%2Fdashboard',
    icon: <img src={logos.HOPSITABLE} />,
    id: Integrations.hospitable,
    tooltip: 'Hospitable',
  },
  {
    url: 'https://dashboard.hostaway.com/login',
    icon: <img src={logos.HOSTAWAY} />,
    id: Integrations.hostaway,
    tooltip: 'Hostaway',
  },
  {
    url: 'https://platform.hostfully.com/login.jsp',
    icon: <img src={logos.HOSTFULLY} />,
    id: Integrations.hostfully,
    tooltip: 'Hostfully',
  },
  {
    url: 'https://www.igms.com/app/login.html',
    icon: <img src={logos.IGMS} />,
    id: Integrations.igms,
    tooltip: 'iGMS',
  },
  {
    url: 'https://owners.lmpm.com/login/',
    icon: <img src={logos.LMPM} />,
    id: Integrations.lmpm,
    tooltip: 'LMPM',
  },
  {
    url: 'https://app.lodgify.com/rentals-list',
    icon: <img src={logos.LODGIFY} />,
    id: Integrations.lodgify,
    tooltip: 'Lodgify',
  },
  {
    url: 'https://secure.ownerreservations.com/login?returnUrl=',
    icon: <img src={logos.OWNERREZ} />,
    id: Integrations.ownerrez,
    tooltip: 'OwnerRez',
  },
  {
    url: 'https://app.stayfi.com/sign_in',
    icon: <img src={logos.STAYFI} />,
    id: Integrations.stayfi,
    tooltip: 'StayFi',
  },
  {
    url: 'https://hub.touchstay.com/login/',
    icon: <img src={logos.TOUCHSTAY} />,
    id: Integrations.touchstay,
    tooltip: 'TouchStay',
  },
  {
    url: 'https://owners.vacasa.com/login',
    icon: <img src={logos.VACASA} />,
    id: Integrations.vacasa,
    tooltip: 'Vacasa',
  },
  {
    url: 'https://www.vrbo.com/auth/ui/login?service=https%3A%2F%2Fwww.vrbo.com%2Fp%2Fhome%2Fvalidate-ticket%3Forigin%3D%252Fp%252Fhome%253Freferrer_page_location%253Dhomepage%2526redirectTo%253D%25252F%26site%3Dvrbo&screen=login&treatment=2fa',
    icon: <img src={logos.VRBO} />,
    id: Integrations.vrbo,
    tooltip: 'Vrbo',
  },
];

interface ILogoLinksProps {
  highlightIntegration?: Integrations;
}

const LogoLinks = ({ highlightIntegration }: ILogoLinksProps) => {
  return (
    <div className="ShareModal-links">
      <Typography color="primary" variant="h6" className="ShareModal-links-header">
        Add to your messaging on:
      </Typography>
      <div className="ShareModal-links-container">
        {links.map(({ url, icon, id, tooltip }) => (
          <a
            title={tooltip}
            className={classNames(
              'ShareModal-link',
              highlightIntegration && highlightIntegration !== id && 'blur',
            )}
            key={url}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {icon}
          </a>
        ))}
      </div>
    </div>
  );
};

export default LogoLinks;
