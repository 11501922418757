import { post, patch, get, put } from 'api';
import useSWR from 'swr';

export function updateStore(storeId, params) {
  const url = `/services/store/${storeId}/`;
  return patch(url, params, {
    f2bDict: {
      street1: 'street1',
      street2: 'street2',
    },
  });
}

export const getStoreDetailsURL = (storeId) => `/services/store/${storeId}/`;

const getStoreDetails = (storeId) => get(getStoreDetailsURL(storeId));

export const useStoreDetails = (storeId, enabled = true) => {
  const key = getStoreDetailsURL(storeId);
  const { data, error } = useSWR(enabled ? key : undefined, () => getStoreDetails(storeId));
  return {
    data: data?.data,
    isLoading: !error && !data,
    error,
  };
};

export const duplicateStore = async ({ storeId, ...params }) => {
  const result = await post(`/services/store/copy/${storeId}/`, params);
  return result;
};

export function updateStores({ stores = [] }) {
  const payload = stores.map((store) => ({
    id: store.id,
    payoutMethod: store.payoutMethod,
  }));
  return put('/services/store/bulk-update/', payload);
}

export function storeSetLocalProducts(storeId, payload) {
  const url = `/services/store/${storeId}/set-local-products/`;
  return post(url, payload);
}

// Create Single Store
export async function createStore({ owner, country, ...values }) {
  const result = await post(
    '/services/store/create/',
    {
      ownerId: owner,
      country,
      isActive: true,
      ...values,
    },
    {
      f2bDict: {
        street1: 'street1',
        street2: 'street2',
      },
    },
  );
  return result;
}

// Mass Store Create
export async function createStores({ owner, stores = [] }) {
  const payload = stores.map((store) => ({
    ownerId: owner,
    isActive: true,
    ...store,
  }));
  return post('/services/store/bulk-create/', payload, {
    f2bDict: {
      street1: 'street1',
      street2: 'street2',
    },
  });
}
