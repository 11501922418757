import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { ArrowRight as ArrowRightIcon, ArrowLeft as ArrowLeftIcon } from 'react-feather';
import { FieldValues, useFormContext, UseFormReturn } from 'react-hook-form';
import { Dropdown, Menu } from 'antd';
import { Button } from 'ui-kit';
import { useStepWizard, StepWizardContext } from '../StepWizard.context';
import './StepFooter.scss';

interface Props {
  labelStep?: string;
  onSubmit: (stepCtx: StepWizardContext, formCtx: UseFormReturn<FieldValues, any>) => void;
  onCancel?: () => void;
  setIsMassCreate?: React.Dispatch<React.SetStateAction<boolean>>;
}

enum DropdownButtonOptions {
  CREATE = 'Save to store',
  MASS_CREATE = 'Save and add tile to all stores',
}

const StepFooter: React.FC<Props> = ({ labelStep = 'Next', onSubmit, setIsMassCreate }) => {
  const stepCtx = useStepWizard();
  const formCtx = useFormContext();

  const [buttonText, setButtonText] = useState(DropdownButtonOptions.CREATE);

  useEffect(() => {
    setIsMassCreate(buttonText === DropdownButtonOptions.MASS_CREATE);
  }, [buttonText]);

  const handleMenuClick = useCallback((e: any) => {
    setButtonText(e.key as DropdownButtonOptions);
  }, []);

  const menu = useMemo(
    () => (
      <Menu onClick={handleMenuClick}>
        {buttonText === DropdownButtonOptions.CREATE ? (
          <Menu.Item key={DropdownButtonOptions.MASS_CREATE}>
            {DropdownButtonOptions.MASS_CREATE}
          </Menu.Item>
        ) : (
          <Menu.Item key={DropdownButtonOptions.CREATE}>{DropdownButtonOptions.CREATE}</Menu.Item>
        )}
      </Menu>
    ),
    [buttonText],
  );

  return (
    <div className="Step-footer">
      {stepCtx.step > 1 && (
        <Button
          className="Step-footer-button__back"
          color="textGray"
          variant="text"
          startIcon={<ArrowLeftIcon />}
          onClick={stepCtx.handelBack}
        >
          Back
        </Button>
      )}
      {stepCtx.isLastStep ? (
        <Dropdown.Button
          className="dropdown-btn"
          overlay={menu}
          placement="bottomRight"
          trigger={['click']}
          disabled={!formCtx.formState.isValid || formCtx.formState.isSubmitting}
          htmlType="submit"
        >
          {buttonText}
        </Dropdown.Button>
      ) : (
        <Button
          variant="contained"
          color="primary"
          disabled={!formCtx.formState.isValid}
          loading={formCtx.formState.isSubmitting}
          endIcon={!stepCtx.isLastStep ? <ArrowRightIcon /> : null}
          {...(onSubmit
            ? { onClick: () => onSubmit(stepCtx, formCtx), type: 'button' }
            : { type: 'submit' })}
        >
          {labelStep}
        </Button>
      )}
    </div>
  );
};

export default StepFooter;
