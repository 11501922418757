import React, { useState } from 'react';
import NewProductStepper from 'components/NewProductStepper';
import { Modal, Typography } from 'ui-kit';
import './NewProductModal.scss';
import ConfirmModal from 'components/ConfirmModal';
import { createProduct } from 'api/products';
import { useStore } from 'contexts/store';
import { mutate } from 'swr';
import { message } from 'antd';
import { NewProductMode } from 'components/NewProductStepper/NewProductStepper';
import { getStoreDetailsURL, updateStore } from 'api/store';

interface Props {
  isOpen: boolean;
  mode?: NewProductMode;
  onCancel: () => void;
}

// TODO Change this after APIs iteration
interface ProductInfo {
  productGroup?: number;
}

const NewProductModal: React.FC<Props> = ({ isOpen, onCancel, mode = 'default' }) => {
  const [isConfirmModalVisible, setConfirmModalVisible] = useState(false);
  const { id: storeId, hasAddedProduct } = useStore();
  const [massCreate, setIsMassCreate] = useState<boolean>(false);

  const toogleConfirmModal = () => {
    setConfirmModalVisible((prev) => !prev);
  };

  const onConfirmClose = () => {
    toogleConfirmModal();
    onCancel();
  };

  const handleSubmit = (product) => {
    return createProduct({ store: storeId, massCreate, ...product })
      .then((response) => {
        if (response.ok) {
          mutate(`/services/product/search/${storeId}`);
          if (!hasAddedProduct) {
            updateStore(storeId, { hasAddedProduct: true }).then(() => {
              mutate(getStoreDetailsURL(storeId));
            });
          }
          // TODO Change this after APIs iteration
          const data = response.data as ProductInfo;
          const productGroup = data.productGroup;
          if (massCreate) {
            if (productGroup) {
              message.success('Product successfully created and tile added to all stores!');
            } else {
              message.warning(
                'Product successfully created, but tile cannot be added to all stores because it is already in all stores.',
              );
            }
          } else {
            message.success('Product successfully created!');
          }
          onCancel();
        } else {
          message.error(response.errors?.nonFields?.[0] || response.errors?.fields?.[0]);
        }
      })
      .catch(() => {
        message.error('Something went wrong! Please try again later');
      });
  };

  const onChangeStep = () => {
    const modalElement = document.getElementsByClassName('ant-modal-wrap')?.[0];
    modalElement.scrollTop = 0;
  };

  return (
    <>
      <Modal
        className="NewProductModal"
        visible={isOpen}
        footer={null}
        destroyOnClose
        maskClosable={false}
        onCancel={toogleConfirmModal}
      >
        <NewProductStepper
          onCancel={toogleConfirmModal}
          onSubmit={handleSubmit}
          onChangeStep={onChangeStep}
          mode={mode}
          massCreate={massCreate}
          setIsMassCreate={setIsMassCreate}
        />
      </Modal>

      <ConfirmModal
        visible={isConfirmModalVisible}
        confirmText="Confirm"
        cancelText="Cancel"
        onCancel={toogleConfirmModal}
        onConfirm={onConfirmClose}
        destroyOnClose
      >
        <>
          <Typography variant="h4">Are you sure?</Typography>
          <Typography variant="body2" color="textGray">
            You will lose all the filled info.
          </Typography>
        </>
      </ConfirmModal>
    </>
  );
};

export default NewProductModal;
