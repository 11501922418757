import React from 'react';
import { Radio as AntRadio, RadioGroupProps } from 'antd';
import clsx from 'clsx';
import './Radio.scss';

type Direction = 'vertical' | 'horizontal';

interface Props extends RadioGroupProps {
  direction?: Direction;
}

export const Radio = (props) => {
  return <AntRadio className={clsx('ui-kit-radio')} {...props} />;
};

const BASE_CLASS = 'ui-kit-radio-group';

export const RadioGroup = React.forwardRef(({ direction = 'vertical', ...rest }: Props, _ref) => (
  <AntRadio.Group className={clsx(BASE_CLASS, `${BASE_CLASS}-${direction}`)} {...rest} />
));
