import React, { useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { v4 as uuidv4 } from 'uuid';

import { Form } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { Link, Typography, Checkbox, Modal } from 'ui-kit';
import InputPhoneMask from 'components/Form/InputPhoneMask';
import { Input } from 'components/Form';

import './ProductDelegatesCreateModal.scss';
import {
  EMPTY_PHONE_NUMBER_REGEX,
  formatPhoneNumber,
  PHONE_NUMBER_REGEX,
  validateAndFormatPhoneNumber,
} from 'components/Form/InputPhoneMask/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';

interface IProductDelegatesCreateModal {
  isOpen: boolean;
  onConfirm: ({ data }: { data: IProductDelegate }) => void;
  onCancel: () => void;
  disabledSms: boolean;
  initialData?: IProductDelegate;
}

export interface IProductDelegate {
  id?: number;
  email: string;
  isShowPrice: boolean;
  phoneNumber: string;
  isRequestSmsEnabled: boolean;
  isSalesSmsEnabled: boolean;
  uuid: string;
}

const validDelegateSchema = yup.object({
  email: yup.string().email('Please input a valid email.').required('Email address required.'),
  phoneNumber: yup
    .string()
    .nullable()
    .test('valid-phone-number', 'Please input a valid phone number.', (value) => {
      if (
        _.isEmpty(value) ||
        value.match(EMPTY_PHONE_NUMBER_REGEX) ||
        !_.isNull(validateAndFormatPhoneNumber({ phoneNumber: value }))
      ) {
        return true;
      }

      return PHONE_NUMBER_REGEX.test(value);
    }),
});

const ProductDelegatesCreateModal = ({
  isOpen,
  onConfirm,
  onCancel,
  disabledSms,
  initialData,
}: IProductDelegatesCreateModal) => {
  const uuid = uuidv4();
  const { control, getValues, reset, handleSubmit, watch } = useForm<IProductDelegate>({
    mode: 'all',
    defaultValues: {
      id: null,
      email: '',
      isShowPrice: false,
      phoneNumber: '',
      isRequestSmsEnabled: false,
      isSalesSmsEnabled: false,
      uuid,
    },
    resolver: yupResolver(validDelegateSchema),
  });

  const phoneNumberField = watch('phoneNumber');

  useEffect(() => {
    if (!isOpen) {
      reset();
    } else if (isOpen && initialData) {
      reset(initialData);
    } else {
      reset({
        id: null,
        email: '',
        phoneNumber: '',
        isShowPrice: false,
        isRequestSmsEnabled: false,
        isSalesSmsEnabled: false,
        uuid,
      });
    }
  }, [isOpen, reset]);

  const handleConfirm = useCallback(() => {
    const data = getValues();

    const formattedData = {
      ...data,
      phoneNumber: formatPhoneNumber({ phone: data.phoneNumber }),
    };

    onConfirm({ data: formattedData });
  }, [onConfirm, getValues]);

  const handleCancel = useCallback(() => {
    onCancel();
    reset();
  }, [onCancel, reset]);

  const disabledSMSCheckboxes = useMemo(
    () =>
      disabledSms ||
      !validateAndFormatPhoneNumber({
        phoneNumber: formatPhoneNumber({ phone: phoneNumberField }),
      }),
    [disabledSms, phoneNumberField],
  );

  return (
    <Modal
      className="DelegateModal"
      visible={isOpen}
      onOk={handleSubmit(handleConfirm)}
      onCancel={handleCancel}
      okText="Save"
      cancelText="Cancel"
      okType="primary"
      destroyOnClose
      okButtonProps={{
        htmlType: 'submit',
      }}
    >
      <Typography className="DelegateModal_title" variant="h6">
        {initialData ? 'Edit Product Contact' : 'Add Product Contact'}
      </Typography>

      <Form layout="vertical">
        <Controller
          name="id"
          control={control}
          render={({ field: { value } }) => <input type="hidden" value={value} />}
        />
        <Controller
          name="uuid"
          control={control}
          render={({ field: { value } }) => <input type="hidden" value={value} />}
        />
        <Controller
          name="isShowPrice"
          control={control}
          render={({ field: { name, value, onChange } }) => (
            <Form.Item name={name}>
              <Checkbox onChange={onChange} checked={value}>
                Show prices in emails and text messages
              </Checkbox>
            </Form.Item>
          )}
        />
        <Controller
          name="email"
          control={control}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <Form.Item
              required
              name={name}
              label="Email address"
              className="email-label"
              tooltip={{
                title: 'Emails include request and approve, sales, service info, and reminders.',
                icon: <ExclamationCircleOutlined />,
              }}
              validateStatus={error ? 'error' : ''}
              help={error?.message}
              initialValue={value}
            >
              <Input
                className=""
                defaultValue={value}
                onChange={onChange}
                placeholder="email address..."
              />
            </Form.Item>
          )}
        />
        <Controller
          name="phoneNumber"
          control={control}
          render={({ field: { name, value, onChange, onBlur }, fieldState: { error } }) => (
            <Form.Item
              name={name}
              label="Phone number for text messages"
              className="phone-number-label"
              tooltip={{
                title: 'Available in the US and Canada.',
                icon: <ExclamationCircleOutlined />,
              }}
              validateStatus={error ? 'error' : ''}
              help={error ? error?.message : null}
              initialValue={value}
            >
              <InputPhoneMask
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                disabled={disabledSms}
              />
            </Form.Item>
          )}
        />
        <div className="DelegateModal_list_notifications">
          <p className="label-title">Enable text notifications for:</p>
          <Controller
            name="isRequestSmsEnabled"
            control={control}
            render={({ field: { name, value, onChange } }) => (
              <Form.Item name={name}>
                <Checkbox onChange={onChange} checked={value} disabled={disabledSMSCheckboxes}>
                  Request and Approve: Request, Approve, Deny, Cancelled, Expired
                </Checkbox>
              </Form.Item>
            )}
          />
          <Controller
            name="isSalesSmsEnabled"
            control={control}
            render={({ field: { name, value, onChange } }) => (
              <Form.Item name={name}>
                <Checkbox onChange={onChange} checked={value} disabled={disabledSMSCheckboxes}>
                  Sales: Purchase, Cancellation, Service Reminders, Inventory
                </Checkbox>
              </Form.Item>
            )}
          />
        </div>
      </Form>

      {disabledSms && (
        <Typography variant="caption">
          * <Link href={`/pricing/`}>Upgrade to Pro</Link> for Text Messaging
        </Typography>
      )}
    </Modal>
  );
};
export default ProductDelegatesCreateModal;
