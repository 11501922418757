import React from 'react';
import { Typography } from '../../ui-kit';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import './ConfirmModalMassAddProduct.scss';

const ConfirmModalMassAddProduct = ({
  isOpen,
  onConfirm,
  onCancel,
}: {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}) => (
  <ConfirmModal
    className="ConfirmPropagateModal"
    visible={isOpen}
    onConfirm={onConfirm}
    onCancel={onCancel}
    confirmText="Yes"
    cancelText="No"
    confirmBtnType="primary"
    destroyOnClose
  >
    <Typography className="ConfirmPropagateModal_title" variant="h6">
      Add Tile to All Stores
    </Typography>
    <p>
      Are you sure you want to add this tile to all stores? Once added, tiles must be modified or
      deleted in each store.
    </p>
  </ConfirmModal>
);

export default ConfirmModalMassAddProduct;
