import React, { useEffect } from 'react';
import Typography from 'ui-kit/Typography';
import StepFooter from './StepFooter';
import './Step.scss';
import { StepWizardContext } from '../StepWizard.context';
import { UseFormReturn, FieldValues, useFormContext, Resolver } from 'react-hook-form';

export interface StepBaseProps {
  step: React.ReactNode;
  customFooter?: React.ReactNode;
  label?: string;
  title: string;
  onSubmit?: (stepCtx: StepWizardContext, formCtx: UseFormReturn<FieldValues, any>) => void;
  onCancel?: () => void;
  resolver?: Resolver<any>;
  isSubmitting?: boolean;
  setIsMassCreate?: React.Dispatch<React.SetStateAction<boolean>>;
}

const Step: React.FC<StepBaseProps> = ({
  step,
  title,
  label,
  customFooter,
  onSubmit,
  onCancel = () => null,
  setIsMassCreate,
}) => {
  const { trigger } = useFormContext();
  useEffect(() => {
    trigger();
  }, []);

  return (
    <div className="Step-container">
      <Typography variant="h6" className="Step-title">
        {title}
      </Typography>
      <div className="Step-container-content">{step}</div>
      {customFooter ?? (
        <StepFooter
          labelStep={label}
          onSubmit={onSubmit}
          onCancel={onCancel}
          setIsMassCreate={setIsMassCreate}
        />
      )}
    </div>
  );
};

export default Step;
