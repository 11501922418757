import React, { useState } from 'react';
import { FormProvider, useForm, UseFormReturn, DeepPartial } from 'react-hook-form';
import { Stepper } from 'ui-kit';
import { StepWizardCtx, useSteps, StepWizardContext } from './StepWizard.context';
import Step, { StepBaseProps } from './Step';
import ConfirmModalMassAddProduct from '../ConfirmModalMassAddProduct';

import './StepWizard.scss';

export interface StepWaizardProps<T> {
  initialStep: number;
  steps: StepBaseProps[];
  initialValues: DeepPartial<T>;
  onGoBack?: (stepCtx: StepWizardContext, formCtx: UseFormReturn<T, any>) => void;
  onChangeStep?: () => void;
  onCancel?: () => void;
  onSubmit?: (values: T) => Promise<void> | void;
  disabledSteps?: number[];
  massCreate?: boolean;
  setIsMassCreate?: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function StepWizard<T>({
  steps,
  initialStep,
  initialValues,
  onGoBack,
  onChangeStep,
  onSubmit = () => null,
  massCreate,
  setIsMassCreate,
}: StepWaizardProps<T>) {
  const stepCtx = useSteps({ steps, initialStep, onChangeStep, handelBack });
  const { step, next, prev, isLastStep } = stepCtx;
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const getStep = React.useCallback((stepNumber: number) => {
    const step = steps[stepNumber - 1];
    if (!React.isValidElement(step.step)) {
      throw new Error(`Step is not a valid element`);
    }
    return step;
  }, []);

  const stepProps = getStep(step);
  const methods = useForm<T>({
    defaultValues: initialValues,
    resolver: stepProps?.resolver,
    mode: 'onChange',
  });

  const handleFormSubmittion = (event) => {
    event.preventDefault();
    if (isLastStep && massCreate) {
      setConfirmModalOpen(true);
    } else {
      methods.handleSubmit(handleSubmit)();
    }
  };

  const handleSubmit = async (data) => {
    if (isLastStep) {
      await onSubmit({ ...data, product: { ...data.product, mass_create: massCreate } });
      methods.reset();
      return;
    }
    next();
    methods.reset(data);
  };

  const handleConfirmMassCreate = () => {
    methods.handleSubmit(handleSubmit)();
    setConfirmModalOpen(false);
  };

  const handleCancelMassCreate = () => {
    setConfirmModalOpen(false);
  };

  function handelBack() {
    if (onGoBack) {
      onGoBack(stepCtx, methods);
      return;
    }
    prev();
  }

  return (
    <StepWizardCtx.Provider value={{ ...stepCtx, handelBack }}>
      <div className="StepWizard-steps">
        <div className="StepWizard-steps-content">
          <Stepper steps={steps.length} activeStep={step} />
        </div>
      </div>
      <FormProvider {...methods}>
        <form className="StepWizard-form" onSubmit={handleFormSubmittion}>
          <ConfirmModalMassAddProduct
            isOpen={confirmModalOpen}
            onConfirm={handleConfirmMassCreate}
            onCancel={handleCancelMassCreate}
          />
          <Step key={step} setIsMassCreate={setIsMassCreate} {...stepProps} />
        </form>
      </FormProvider>
    </StepWizardCtx.Provider>
  );
}
