import { message } from 'antd';
import { useAuth } from 'contexts/auth/auth';
import { useStore } from 'contexts/store';
import { useCallback, useEffect, useMemo } from 'react';

export enum OnboardingStep {
  createAccount,
  createStore,
  firstProduct,
  shareStore,
}
export interface StepItem {
  id: OnboardingStep;
  title: string;
  description: string;
  action?: (id: OnboardingStep) => void;
}

interface OnboardingValues {
  completedSteps: OnboardingStep[];
  steps: StepItem[];
  activeStep: StepItem;
  isCompleted: boolean;
  initialCollapseStatus: boolean;
  handleCollapseStatus: (status: boolean) => void;
}

const steps: StepItem[] = [
  {
    id: OnboardingStep.createAccount,
    title: 'Create your account',
    description: '2 minutes',
  },
  {
    id: OnboardingStep.createStore,
    title: 'Create your first store',
    description: '3 minutes',
  },
  {
    id: OnboardingStep.firstProduct,
    title: 'Add your first product',
    description: '4 minutes',
  },
  {
    id: OnboardingStep.shareStore,
    title: 'Share your store with guests',
    description: '2 minutes',
  },
];

interface Props {
  actions: Partial<Record<OnboardingStep, StepItem['action']>>;
}

const getCompletedSteps = (store, owner): OnboardingStep[] => {
  const items: OnboardingStep[] = [];
  if (owner?.id) {
    items.push(OnboardingStep.createAccount);
  }

  if (store?.id) {
    items.push(OnboardingStep.createStore);
  }

  if (store?.hasAddedProduct) {
    items.push(OnboardingStep.firstProduct);
  }

  if (store?.hasSharedStore) {
    items.push(OnboardingStep.shareStore);
  }
  return items;
};

const saveCacheCollapseStatus = (storeId, status: boolean) => {
  localStorage.setItem('onboardingStatus', JSON.stringify({ [storeId]: status }));
};

const getCacheCollapseStatus = (storeId: number) => {
  const cacheStatus = JSON.parse(localStorage.getItem('onboardingStatus'));
  return cacheStatus?.[storeId] ?? true;
};

export default function useOnboardingSteps({ actions }: Props): OnboardingValues {
  const store = useStore();
  const owner = useAuth();

  const initialCompletedSteps = useMemo(() => getCompletedSteps(store, owner), []);
  const completedSteps = useMemo(() => getCompletedSteps(store, owner), [owner, store]);
  const initialCollapseStatus = useMemo(() => getCacheCollapseStatus(store.id), []);

  const proxyAction = useCallback(
    (step: StepItem) => (id: OnboardingStep) => {
      const action = actions?.[step.id] ?? (() => null);
      if (id === step.id) {
        return action(id);
      }
      return (() => null)();
    },
    [],
  );

  const handleCollapseStatus = (status) => {
    saveCacheCollapseStatus(store.id, status);
  };

  const activeStep = useMemo(() => {
    const tempSteps = structuredClone(steps).filter(({ id }) => !completedSteps.includes(id));
    const step = tempSteps.shift();
    return { ...step, action: proxyAction(step) };
  }, [completedSteps.length]);

  const isCompleted = useMemo(
    () => completedSteps.length === steps.length,
    [completedSteps.length],
  );

  useEffect(() => {
    if (isCompleted && initialCompletedSteps.length !== completedSteps.length) {
      message.success('You’ve successfully completed your store set-up!');
    }
  }, [isCompleted]);

  return {
    isCompleted,
    completedSteps,
    steps,
    activeStep,
    initialCollapseStatus,
    handleCollapseStatus,
  };
}
