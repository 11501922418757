import { ProductSubcategory } from 'api/types/product';
import {
  addOns,
  externalLink,
  foodAndBeverage,
  goodsAndEssentials,
  guestServices,
  rentals,
  somethingElse,
} from './icons';
import { PaymentPlan } from 'api/types/owner';

export interface Category {
  id: ProductSubcategory;
  title: string;
  description: string;
  extraProps?: string;
  icon: string | null;
  minimumPlan?: PaymentPlan;
}

export const subcategories: Category[] = [
  {
    id: ProductSubcategory.AddOns,
    title: 'Add-ons',
    description:
      'Add-ons are anything that make your guest’s stay easier  - late-check out, midstay cleaning, etc.',
    icon: addOns,
  },
  {
    id: ProductSubcategory.FoodAndBeverage,
    title: 'Food and beverage',
    description:
      'Give your guests the ease of a pre-stocked pantry, snack basket, or even a home-cooked breakfast.',
    icon: foodAndBeverage,
  },
  {
    id: ProductSubcategory.GoodsAndEssentials,
    title: 'Goods and essentials',
    description:
      'Phone chargers, toiletries, merch, firewood and more - anything your guest can use or take with them after their stay.',
    icon: goodsAndEssentials,
  },
  {
    id: ProductSubcategory.GuestServices,
    title: 'Guest services',
    description:
      'Personal massage? Private yoga class? Fresh flowers? Connect your guests with a one-of-a-kind experience.',
    icon: guestServices,
  },
  {
    id: ProductSubcategory.Rentals,
    title: 'Rentals',
    description:
      'Rent your kayaks, scooters, bikes, or even speciality instruments out to your guests with ease.',
    icon: rentals,
  },
  {
    id: ProductSubcategory.Link,
    title: 'External Link',
    description: 'Link to an external website or product.',
    icon: externalLink,
    minimumPlan: PaymentPlan.Pro,
  },
  {
    id: ProductSubcategory.SomethingElse,
    title: 'Something else',
    description:
      'Have something unique to offer that doesn’t fit a category? You can still create it here.',
    icon: somethingElse,
  },
];
