import { ProductSubcategory } from 'api/types/product';
import React from 'react';
import { Controller, ControllerRenderProps, FieldValues, useFormContext } from 'react-hook-form';
import CardList from '../CardList';
import { subcategories } from './utils';
import { useAuth } from 'contexts/auth/auth';
import { useOwnerDetails } from 'api/owners';
import { Spinner } from 'ui-kit';
import { paymentPlanOrder } from 'api/types/owner';

const SelectCategory: React.FC = () => {
  const { id: ownerId } = useAuth();
  const { data, isLoading } = useOwnerDetails(ownerId);
  const { plan } = data || {};
  const { control, setValue, getValues, unregister } = useFormContext();
  const handleCategory = (
    value: ProductSubcategory,
    field: ControllerRenderProps<FieldValues, 'subcategory'>,
  ) => {
    const { product } = getValues();
    if (value !== field.value && product) {
      unregister('product');
      setValue('product', null);
    }
    setValue(field.name, value === field.value ? null : value, { shouldValidate: true });
  };

  if (isLoading) {
    return <Spinner />;
  }
  const availableSubcategories = subcategories.filter((subCategory) =>
    subCategory.minimumPlan
      ? paymentPlanOrder[subCategory.minimumPlan] <= paymentPlanOrder[plan]
      : true,
  );

  return (
    <Controller
      control={control}
      name="subcategory"
      render={({ field }) => {
        return (
          <CardList
            items={availableSubcategories}
            selectedId={field.value}
            onChange={(value) => handleCategory(value, field)}
          />
        );
      }}
    />
  );
};

export default SelectCategory;
