import React from 'react';
import { classNames } from 'utils';
import InputMask from 'react-input-mask';

import './InputPhoneMask.scss';

export interface IInputPhoneMask {
  value: string;
  onChange: (props?) => void;
  onBlur: (props?) => void;
  className?: string;
  mask?: string;
  disabled?: boolean;
}

const InputPhoneMask = ({
  className,
  value,
  onChange,
  onBlur,
  disabled,
  mask = '+1 (999) 999-9999',
}: IInputPhoneMask) => (
  <InputMask
    className={classNames('InputPhoneMask', className)}
    mask={mask}
    value={value}
    onChange={onChange}
    onBlur={onBlur}
    disabled={disabled}
  />
);

export default InputPhoneMask;
