import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Checkbox,
  InputAdornment,
  InputLabel,
  Spinner,
  TextField,
  Tooltip,
  Typography,
} from 'ui-kit';
import { calculateYouMake, formatCurrency, getDigitsFromValue } from 'utils';
import { useStore } from 'contexts/store';
import { useOwnerDetails } from 'api/owners';
import { getFieldError } from '../utils';
import { useAuth } from 'contexts/auth/auth';
import { ExclamationCircleOutlined } from '@ant-design/icons';

interface Props {
  baseClass: string;
}

export default function PriceField({ baseClass }: Props) {
  const { control, watch, setValue } = useFormContext();
  const isGuestPrice = watch('product.isGuestPrice');

  const { id: ownerId } = useAuth();
  const { data, isLoading } = useOwnerDetails(ownerId);
  const { currency, commissionCutoff, commissionLow, commissionHigh } = useStore();
  const hasPremiumPlan = data?.plan !== 'BASIC';

  useEffect(() => {
    if (isGuestPrice) {
      setValue('product.price', '0.00', { shouldValidate: true, shouldDirty: true });
    }
  }, [isGuestPrice]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <Controller
        control={control}
        name="product.price"
        render={({ field: { value, ref, onChange, ...rest }, fieldState }) => {
          const youMake = calculateYouMake({
            price: value,
            commissionLow,
            commissionHigh,
            commissionCutoff,
          });
          return (
            <div className={`${baseClass}-price`}>
              <TextField
                label="Product price"
                placeholder="0.00"
                required={!isGuestPrice}
                InputProps={{
                  startAdornment: <InputAdornment>$</InputAdornment>,
                }}
                inputRef={ref}
                value={value ?? ''}
                onChange={(e) => onChange(getDigitsFromValue(e.target.value))}
                {...getFieldError(fieldState)}
                {...rest}
                disabled={isGuestPrice}
              />
              {!hasPremiumPlan && (
                <div className={`${baseClass}-price-content`}>
                  <InputLabel label="You Make" />
                  <Typography
                    variant="h6"
                    color="secondary"
                    className={`${baseClass}-price-content-value`}
                  >
                    {isGuestPrice ? '-' : formatCurrency(youMake, currency)}
                  </Typography>
                </div>
              )}
            </div>
          );
        }}
      />
      <Controller
        control={control}
        name="product.isGuestPrice"
        render={({ field: { value, onChange } }) => (
          <Checkbox checked={value} onChange={onChange}>
            Guest sets price{' '}
            <Tooltip title="The guest will set the price when ordering the product">
              <ExclamationCircleOutlined />
            </Tooltip>
          </Checkbox>
        )}
      />
    </>
  );
}
