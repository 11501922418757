import React, { useState } from 'react';
import clsx from 'clsx';
import { CircularProgress, Collapse, Typography, Link, Button } from 'ui-kit';
import OnboardingModuleSteps from './OnboardingModuleISteps/OnboadingModuleSteps';
import './OnboardingModule.scss';
import useOnboardingSteps, { OnboardingStep } from 'hooks/useOnboardingSteps';
import NewProductModal from 'components/Dashboard/Store/NewProductModal';
import ShareModal from 'components/Dashboard/Share/ShareModal';

export default function OnboardingModule() {
  const [addProduct, setAddProduct] = useState(false);
  const [shareStore, setShareStore] = useState(false);

  const toogleAddProductModal = () => {
    setAddProduct((prev) => !prev);
  };

  const toogleShareModal = () => {
    setShareStore((prev) => !prev);
  };

  const {
    isCompleted,
    completedSteps,
    steps,
    activeStep,
    initialCollapseStatus,
    handleCollapseStatus,
  } = useOnboardingSteps({
    actions: {
      [OnboardingStep.firstProduct]: () => {
        toogleAddProductModal();
      },
      [OnboardingStep.shareStore]: () => {
        toogleShareModal();
      },
    },
  });

  if (isCompleted) {
    return null;
  }

  return (
    <div className="OnboardingModule-container">
      <Collapse
        initialCollpase={initialCollapseStatus}
        onCollapse={handleCollapseStatus}
        header={(isCollapse) => (
          <div className="OnboardingModule-collapsable-header">
            <Typography
              variant="h5"
              className={clsx('OnboardingModule-collapsable-header__title', {
                'OnboardingModule-collapsable-header__title-collapsed': isCollapse,
              })}
            >
              {!isCollapse ? (
                'Start selling checklist'
              ) : (
                <>
                  {activeStep.id === OnboardingStep.shareStore ? 'Last step: ' : 'Next step: '}
                  <Button
                    variant="text"
                    color="primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      activeStep.action(activeStep.id);
                    }}
                  >
                    {activeStep.title}
                  </Button>
                </>
              )}
            </Typography>
            <CircularProgress steps={steps.length} completedSteps={completedSteps.length} />
          </div>
        )}
      >
        <div className="OnboardingModule-collapsable-content">
          <Typography variant="body2">
            For more information watch <Link href="#">our video library</Link>
          </Typography>

          <OnboardingModuleSteps
            steps={steps}
            completedSteps={completedSteps}
            activeStep={activeStep}
            onStepAction={activeStep.action}
          />
        </div>
      </Collapse>
      <NewProductModal isOpen={addProduct} onCancel={toogleAddProductModal} mode="default" />
      <ShareModal isOpen={shareStore} onClose={toogleShareModal} />
    </div>
  );
}
