import React from 'react';
import { OnboardingStep, StepItem } from 'hooks/useOnboardingSteps';
import OnboardingModuleStep from '../OnboardingModuleStep';
import './OnboardingModuleSteps.scss';

const BASE_CLASS = 'OnboardingModuleSteps';

interface Props {
  steps: StepItem[];
  completedSteps: OnboardingStep[];
  activeStep: StepItem;
  onStepAction: (step: OnboardingStep) => void;
}
export default function OnboardingModuleSteps({
  steps,
  onStepAction,
  completedSteps,
  activeStep,
}: Props) {
  return (
    <div className={BASE_CLASS}>
      {steps.map(({ id, ...rest }) => (
        <OnboardingModuleStep
          key={id}
          isActive={activeStep.id === id}
          isCompleted={completedSteps.includes(id)}
          onAction={() => onStepAction(id)}
          {...rest}
        />
      ))}
    </div>
  );
}
