import React from 'react';
import Typography from 'ui-kit/Typography';
import './CardHeader.scss';

interface Props {
  title?: React.ReactNode;
  subheader?: React.ReactNode;
  action?: React.ReactNode;
}

const BASE_CLASS = 'ui-kit-card-header';

const CardHeader: React.FC<Props> = ({ title = 'title', subheader, action }) => {
  return (
    <div className={BASE_CLASS}>
      <div className={`${BASE_CLASS}__content`}>
        <div className={`${BASE_CLASS}__content-title`}>
          <Typography className={`${BASE_CLASS}__content-title`} variant="subtitle1" rows={2}>
            {title}
          </Typography>
        </div>
        {typeof subheader === 'string' ? (
          <Typography
            className={`${BASE_CLASS}__content-subheader`}
            variant="body2"
            color="textLight"
          >
            {subheader}
          </Typography>
        ) : (
          subheader
        )}
      </div>
      {action && <div className={`${BASE_CLASS}__action`}>{action}</div>}
    </div>
  );
};

export default CardHeader;
