import React from 'react';
import clsx from 'clsx';
import { Typography } from 'ui-kit';
import { Check as CheckIcon } from 'react-feather';
import './OnboardingModuleStep.scss';

const BASE_CLASS = 'OnboardingModuleStep';

interface Props {
  title: string;
  description: string;
  isCompleted?: boolean;
  isActive?: boolean;
  onAction: () => void;
}

export default function OnboardingModuleStep({
  title,
  description,
  isActive = false,
  isCompleted = false,
  onAction,
}: Props) {
  return (
    <div
      role="button"
      className={clsx(`${BASE_CLASS}-container`, {
        [`${BASE_CLASS}-container-complete`]: isCompleted,
        [`${BASE_CLASS}-container-active`]: isActive,
      })}
      onClick={onAction}
    >
      <span className={`${BASE_CLASS}-container__checkbox`}>
        {isCompleted && <CheckIcon size={12} />}
      </span>
      <div className={`${BASE_CLASS}-container__content`}>
        <Typography variant="body1" className={`${BASE_CLASS}-container__content-title`}>
          {title}
        </Typography>
        <Typography variant="body2" className={`${BASE_CLASS}-container__content-description`}>
          {description}
        </Typography>
      </div>
    </div>
  );
}
