import React from 'react';
import { Check as CheckIcon } from 'react-feather';
import clsx from 'clsx';
import './Stepper.scss';

interface Props {
  steps: number | string[];
  activeStep: number;
}

const BASE_CLASS = 'ui-kit-stepper';

const Stepper: React.FC<Props> = ({ steps, activeStep = 1 }) => {
  const arraySteps = Array.isArray(steps) ? steps : Array.from(new Array(steps));
  return (
    <div className={`${BASE_CLASS}-container`}>
      {arraySteps.map((_label, index) => {
        const step = index + 1;
        const isActive = activeStep === step;
        const completed = activeStep > step;
        return (
          <React.Fragment key={index}>
            <div className={clsx(`${BASE_CLASS}-step`)}>
              <span
                className={clsx(
                  `${BASE_CLASS}-step__container`,
                  isActive && `${BASE_CLASS}-step__container-active`,
                  completed && `${BASE_CLASS}-connector__line-completed`,
                )}
              >
                {completed && <CheckIcon />}
              </span>
            </div>
            {step < steps && (
              <div className={clsx(`${BASE_CLASS}-connector`)}>
                <span
                  className={clsx(
                    `${BASE_CLASS}-connector__line`,
                    completed && `${BASE_CLASS}-connector__line-completed`,
                  )}
                />
              </div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default Stepper;
