import React, { useState } from 'react';
import { message } from 'antd';
import copyToClipboard from 'copy-to-clipboard';
import { getStoreDetailsURL, updateStore, useStoreDetails } from 'api/store';
import { mutate } from 'swr';
import { Button, InputHelperText, Modal, Spinner, Tabs } from 'ui-kit';
import './ShareModal.scss';
import LogoLinks from './LogoLinks';
import { getStoreLink } from 'utils/utilsV2';

interface IStoreLinkContentProps {
  storeLink: string;
  storeId: number;
  storeHasSharedStore: boolean;
}

const StoreLinkContent = ({ storeLink, storeId, storeHasSharedStore }: IStoreLinkContentProps) => {
  const handleCopyLink = () => {
    if (!storeHasSharedStore && storeId) {
      updateStore(storeId, { hasSharedStore: true }).then(() => {
        mutate(getStoreDetailsURL(storeId));
        window.fbq?.('trackCustom', 'ShareLink', { storeId });
      });
    }
    copyToClipboard(storeLink);
    message.success('Hooray! Happy guests await!');
  };

  return (
    <div>
      <InputHelperText>This is your store link:</InputHelperText>

      <p className="ShareModal-copyMessage">
        <a className="ShareModal-copyMessage-link">{storeLink}</a>
      </p>
      <div className="ShareModal-messageCopyContainer">
        <Button variant="contained" onClick={handleCopyLink}>
          Copy Link
        </Button>
      </div>
    </div>
  );
};

interface IMessageContent {
  storeLink: string;
  storeId: number;
  storeHasSharedStore: boolean;
}

const MessageContent = ({ storeLink, storeId, storeHasSharedStore }: IMessageContent) => {
  const handleCopyLink = (copyId: string) => {
    const copyText = document.getElementById(copyId).innerText;

    if (!storeHasSharedStore && storeId) {
      updateStore(storeId, { hasSharedStore: true }).then(() => {
        mutate(getStoreDetailsURL(storeId));
        window.fbq?.('trackCustom', 'ShareLink', { storeId });
      });
    }

    copyToClipboard(copyText);
    message.success('Hooray! Happy guests await!');
  };

  return (
    <div>
      <p className="ShareModal-messageTitle">At booking:</p>
      <p className="ShareModal-copyMessage" id="at-booking">
        Thanks for booking! We&apos;ll be in touch with the check-in specifics prior to your
        arrival. If you need driving directions or local recommendations, check out our Welcome
        Guide in the link below. You can also use this link to book an early check-in, late
        checkout, or a local service like massage or private chef. Please reach out if you have any
        other questions.
        <br />
        <a className="ShareModal-copyMessage-link">{storeLink}</a>
      </p>
      <div className="ShareModal-messageCopyContainer">
        <Button variant="contained" onClick={() => handleCopyLink('at-booking')}>
          Copy message
        </Button>
      </div>

      <p className="ShareModal-messageTitle">One week before check-in:</p>
      <p className="ShareModal-copyMessage" id="before-check-in">
        Your trip is coming up! If you&apos;d like to add any pre-order services like early
        check-in, a local massage or a tour guide, click the link below. These services require a
        few days advance notice so we recommend ordering them now if you&apos;re interested.
        Otherwise, we&apos;ll be in touch with entry instructions closer to your stay!
        <br />
        <a className="ShareModal-copyMessage-link">{storeLink}</a>
      </p>
      <div className="ShareModal-messageCopyContainer">
        <Button variant="contained" onClick={() => handleCopyLink('before-check-in')}>
          Copy message
        </Button>
      </div>

      <p className="ShareModal-messageTitle">At check-in:</p>
      <p className="ShareModal-copyMessage" id="after-check-in">
        Your trip is almost here! Look out for check-in instructions headed your way. For additional
        upgrades like extra guest, pet fees, late-checkout or local services click the link below.
        Safe travels!
        <br />
        <a className="ShareModal-copyMessage-link">{storeLink}</a>
      </p>
      <div className="ShareModal-messageCopyContainer">
        <Button variant="contained" onClick={() => handleCopyLink('after-check-in')}>
          Copy message
        </Button>
      </div>

      <p className="ShareModal-messageTitle">
        Additional templates for on-site product sales, rentals, guidebooks and more can be found
        here:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.thehost.co/images-and-guest-messaging"
        >
          https://www.thehost.co/images-and-guest-messaging
        </a>
      </p>
    </div>
  );
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
  storeId?: number;
}

const ShareModal = ({ isOpen, onClose, storeId: guestStoreId }: Props) => {
  const { data, isLoading } = useStoreDetails(guestStoreId, isOpen);
  const { id: storeId, hasClosedShareTutorial, hasSharedStore, enterpriseInfo } = data ?? {};

  const handleCloseShareTutorial = () => {
    updateStore(storeId, { hasClosedShareTutorial: true }).then(() => {
      mutate(getStoreDetailsURL(storeId));
    });
  };

  const [activeTab, setActiveTab] = useState('store-link');

  const { isEnabled, subdomain, isSubdomainEnabled } = enterpriseInfo ?? {};
  const storeLink = getStoreLink({
    storeId: storeId ?? guestStoreId,
    isEnabled,
    subdomain,
    isSubdomainEnabled,
  });

  return (
    <Modal
      className="ShareModal"
      visible={isOpen}
      onCancel={onClose}
      closable={hasClosedShareTutorial}
      title={!hasClosedShareTutorial ? 'Store Sharing Tips' : 'Share Your Store'}
      footer={null}
    >
      <div className="ShareModal-content">
        {isLoading && (
          <div className="spinnerContainer">
            <Spinner size="large" />
          </div>
        )}
        {!hasClosedShareTutorial && !isLoading && (
          <div className="howToShareContainer">
            <ol>
              <li>
                Customize your message to include WHAT you are selling. Tell guests what&apos;s
                available!
              </li>
              <li>See example messages under &quot;Message Templates&quot; on the next page.</li>
              <li>
                Share in your automated messages:
                <ul>
                  <li>after booking</li>
                  <li>one week before arrival</li>
                  <li>at check in</li>
                </ul>
              </li>
            </ol>

            <div className="howToButtonContainer">
              <Button variant="contained" onClick={handleCloseShareTutorial}>
                Let&apos;s go!
              </Button>
            </div>
          </div>
        )}
        {hasClosedShareTutorial && !isLoading && (
          <>
            <Tabs activeKey={activeTab} onChange={(val) => setActiveTab(val)} centered>
              <Tabs.TabPane tab="Store Link" key="store-link">
                <StoreLinkContent
                  storeLink={storeLink}
                  storeId={storeId}
                  storeHasSharedStore={hasSharedStore}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Messaging Templates" key="message">
                <MessageContent
                  storeLink={storeLink}
                  storeId={storeId}
                  storeHasSharedStore={hasSharedStore}
                />
              </Tabs.TabPane>
            </Tabs>
            <LogoLinks />
          </>
        )}
      </div>
    </Modal>
  );
};

export default ShareModal;
