import React from 'react';
import { Row, Col } from 'antd';
import { Checkbox } from 'ui-kit';
import FormControl from 'ui-kit/FormControl';
import InputNew from 'ui-kit/Input/InputNew';
import { useFormContext } from 'react-hook-form';

interface Props {
  value: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement> | number) => void;
  error: boolean;
  errorMessage: React.ReactNode;
}

export default function QuantityField({ error, errorMessage, value, ...field }: Props) {
  const { setValue, watch } = useFormContext();
  const hasQuantity = watch('product.hasQuantity');

  const handleAppliedQty = (e) => {
    const value = !e.target.checked;
    setValue('product.hasQuantity', value, { shouldValidate: true, shouldDirty: true });
    field.onChange(0);
  };

  return (
    <FormControl
      label="How many do you have?"
      disabled={!hasQuantity}
      required
      error={error}
      errorMessage={errorMessage}
    >
      <Row align="middle" gutter={[24, 0]}>
        <Col>
          <InputNew
            type="number"
            disabled={!hasQuantity}
            error={error}
            value={value || ''}
            className="quantity-field"
            {...field}
          />
        </Col>
        <Col>
          <Checkbox onChange={handleAppliedQty} checked={!hasQuantity}>
            n/a
          </Checkbox>
        </Col>
      </Row>
    </FormControl>
  );
}
