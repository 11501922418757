import React, { useMemo } from 'react';
import Typography from 'ui-kit/Typography';
import './CircularProgress.scss';

interface Props {
  steps: number;
  completedSteps: number;
  size?: number;
}

const BASE_CLASS = 'ui-kit-circular-progress';
const TRACK_WIDTH = 3;
export default function CircularProgress({ steps, completedSteps, size = 43 }: Props) {
  const { center, radius, dashArray, dashOffset } = useMemo(() => {
    const progress = Math.round((completedSteps / steps) * 100);
    const center = size / 2;
    const radius = center - TRACK_WIDTH;
    const dashArray = 2 * Math.PI * radius;
    const dashOffset = dashArray * ((100 - progress) / 100);
    return { progress, center, radius, dashArray, dashOffset };
  }, [steps, completedSteps]);

  return (
    <div className={`${BASE_CLASS}-wrapper`} style={{ width: size, height: size }}>
      <Typography variant="body2" color="secondary" className={`${BASE_CLASS}-wrapper__steps`}>
        {completedSteps}/{steps}
      </Typography>
      <svg className={`${BASE_CLASS}-wrapper__circle`} style={{ width: size, height: size }}>
        <circle
          className={`${BASE_CLASS}-wrapper__circle-track`}
          cx={center}
          cy={center}
          r={radius}
        />
        <circle
          className={`${BASE_CLASS}-wrapper__circle-indicator`}
          cx={center}
          cy={center}
          r={radius}
          strokeDasharray={dashArray}
          strokeDashoffset={dashOffset}
        />
      </svg>
    </div>
  );
}
