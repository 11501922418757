import React from 'react';
import CardListItem from './CardListItem';
import './CardList.scss';

interface Props<T extends { id: T['id']; title: string; description: string }> {
  onChange: (id: T['id']) => void;
  selectedId: T['id'];
  items: T[];
  trimDescription?: boolean;
}

export default function CardList<T extends { id: T['id']; title: string; description: string }>({
  onChange,
  selectedId,
  items,
  trimDescription,
}: Props<T>) {
  return (
    <div className="CardList">
      <div className="CardList-content">
        {items.map((item, index) => (
          <CardListItem
            key={index}
            isSelected={selectedId === item.id}
            onChange={onChange}
            trimDescription={trimDescription}
            {...item}
          />
        ))}
      </div>
    </div>
  );
}
