import { BASE_URL, get, post, usePaginatedFetch } from 'utils/sdk';
import { EOrderCancelReason, OrderStatus } from './types/order';
import { Currency } from './types/store';
import _ from 'lodash';
import { formatGetParams } from 'utils/utilsV2';
import { ERefundType } from 'components/RefundButton/contants';

export interface IOrderProduct {
  id: number;
  product_name: string;
  quantity: number;
  delivery_date: string;
  price: string;
  amount_to_refund: string; // This is the amount that will be refunded - the price + commission
  currency: Currency;
  non_refundable_reason: string;
  is_refunded: boolean;
}
export interface IOrder {
  id: number;
  date_created: string;
  products: Array<IOrderProduct>;
  store: {
    id: number;
    store_name: string;
  };
  order_number: string;
  status: OrderStatus;
  your_profit: string;
  grand_total: string;
  currency: Currency;
  guest_name: string;
  owner_order_uuid: string;
  is_refundable: boolean;
  non_refundable_reason: string;
  cancel_reason: EOrderCancelReason;
}

export const useOrders = ({
  storeId,
  status,
  startDate,
  endDate,
}: {
  storeId?: number;
  status?: OrderStatus;
  startDate?: string;
  endDate?: string;
}) => {
  let filters;

  if (!_.isUndefined(status)) {
    filters = { status };
  }
  if (!_.isUndefined(startDate)) {
    filters = { ...filters, start_date: startDate };
  }
  if (!_.isUndefined(endDate)) {
    filters = { ...filters, end_date: endDate };
  }

  if (!_.isUndefined(storeId)) {
    filters = { ...filters, store_id: storeId };
  }

  return usePaginatedFetch<IOrder>({
    url: `services/order/list/`,
    params: { limit: 10, ...filters },
  });
};

export const exportOrders = ({
  storeId,
  status,
  startDate,
  endDate,
}: {
  storeId?: number;
  status?: OrderStatus;
  startDate?: string;
  endDate?: string;
}) => {
  let filters;

  if (!_.isUndefined(status)) {
    filters = { status };
  }
  if (!_.isUndefined(startDate)) {
    filters = { ...filters, start_date: startDate };
  }
  if (!_.isUndefined(endDate)) {
    filters = { ...filters, end_date: endDate };
  }

  if (!_.isUndefined(storeId)) {
    filters = { ...filters, store_id: storeId };
  }

  return get<
    Array<{
      guest_name: string;
      date_created: string;
      order_number: string;
      total_amount: string;
      total_owner_amount: string;
    }>
  >(
    `${BASE_URL}services/order/export-orders/${formatGetParams({
      ...filters,
    })}`,
  );
};

export const exportOrderProducts = ({
  storeId,
  status,
  startDate,
  endDate,
}: {
  storeId?: number;
  status?: OrderStatus;
  startDate?: string;
  endDate?: string;
}) => {
  let filters;

  if (!_.isUndefined(status)) {
    filters = { status };
  }
  if (!_.isUndefined(startDate)) {
    filters = { ...filters, start_date: startDate };
  }
  if (!_.isUndefined(endDate)) {
    filters = { ...filters, end_date: endDate };
  }

  if (!_.isUndefined(storeId)) {
    filters = { ...filters, store_id: storeId };
  }

  return get<
    Array<{
      guest_name: string;
      product_name: string;
      date_created: string;
      total_amount: string;
      your_profit: string;
      order_number: string;
    }>
  >(
    `${BASE_URL}services/order/export-orderproducts/${formatGetParams({
      ...filters,
    })}`,
  );
};

export const refundOrder = ({ orderId }: { orderId: number }) =>
  post(`${BASE_URL}services/order/${orderId}/refund/`, {});

export const refundOrderProduct = ({ orderProductId }: { orderProductId: number }) =>
  post(`${BASE_URL}services/orderproduct/${orderProductId}/refund/`, {});

export const getRefundStatus = ({
  refundType,
  refundRequestId,
}: {
  refundType: ERefundType;
  refundRequestId: number;
}) => {
  if (refundType === ERefundType.ORDER) {
    return get<{ is_refunded: boolean }>(
      `${BASE_URL}services/order/${refundRequestId}/is-refunded/`,
    );
  }

  return get<{ is_refunded: boolean }>(
    `${BASE_URL}services/orderproduct/${refundRequestId}/is-refunded/`,
  );
};
