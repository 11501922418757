import React from 'react';
import PropTypes from 'prop-types';
import { Input as AntInput } from 'antd';
import { classNames } from 'utils';
import Checkbox from './Checkbox';
import Password from './Password';
import './Input.scss';

export default function Input({ className, ...rest }) {
  return <AntInput className={classNames('Input', className)} {...rest} />;
}

Input.Checkbox = Checkbox;
Input.Password = Password;
Input.TextArea = AntInput.TextArea;

Input.propTypes = {
  className: PropTypes.string,
};
