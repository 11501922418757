import { useCallback, useEffect, useState } from 'react';
import { ERefundType } from './contants';
import { getRefundStatus } from 'api/order_sdk';

const POLLING_INTERVAL = 3000; // Poll every 3 seconds

// Polling Hook
export const usePollingRefund = (
  refundType: ERefundType,
  itemIdForRefund: number, // order id or order product id
  interval: number = POLLING_INTERVAL,
) => {
  const [isTaskReady, setIsTaskReady] = useState(false);
  const [isPolling, setIsPolling] = useState(true);

  const pollTaskStatus = useCallback(async () => {
    try {
      if (itemIdForRefund === null) {
        return;
      }

      const { is_refunded } = await getRefundStatus({
        refundRequestId: itemIdForRefund,
        refundType,
      });

      if (is_refunded) {
        setIsTaskReady(true);
        setIsPolling(false); // Stop polling when task is ready
      }
    } catch (error) {
      setIsPolling(false); // Stop polling on error
    }
  }, [itemIdForRefund, refundType]);

  useEffect(() => {
    if (isPolling) {
      const pollingInterval = setInterval(() => {
        pollTaskStatus();
      }, interval);

      return () => clearInterval(pollingInterval);
    }
  }, [pollTaskStatus, isPolling, interval]);

  return { isTaskReady, isPolling };
};
