import React from 'react';
import PropTypes from 'prop-types';
import { Input as AntInput } from 'antd';
import { classNames } from 'utils';
import './Password.scss';

export default function Password({ className, ...rest }) {
  return <AntInput.Password className={classNames('Password', className)} {...rest} />;
}

Password.propTypes = {
  className: PropTypes.string,
};
