import React from 'react';
import { Tabs as AntTabs, TabsProps } from 'antd';
import clsx from 'clsx';
import './Tabs.scss';

interface Props extends TabsProps {}

export default function Tabs({ className, children, ...props }: Props) {
  return (
    <AntTabs className={clsx('ui-kit-tabs', className)} {...props}>
      {children}
    </AntTabs>
  );
}

Tabs.TabPane = AntTabs.TabPane;
